<template>
  <v-overlay
      absolute
      opacity="0.7"
  >
    <h1 class="text-center">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
      <br/>
      {{$vuetify.lang.t('$vuetify.login.loading')}}
    </h1>
  </v-overlay>
</template>

<script>
import client from '@/utils/client';

export default {
  mounted() {
    if(this.$route.params.token) {
      localStorage.setItem('token', this.$route.params.token);
      client.get('/vertraege', {limit:99}).then(response => {
        let mieter = response.data.vertraege.length ? response.data.vertraege[0].mieter : response.data.mitvertraege[0].mieter;
        mieter.vertraege = [];
        response.data.vertraege.forEach(vertrag => {
          vertrag.mieter = null;
          delete vertrag.mieter;
          mieter.vertraege.push(vertrag);
        });
        response.data.mitvertraege.forEach(vertrag => {
          vertrag.mieter = null;
          delete vertrag.mieter;
          mieter.mitvertraege.push(vertrag);
        });
        this.$store.commit('user/load', mieter);
        this.$store.commit('vertrag/load', mieter.vertraege.length ? mieter.vertraege[0] : mieter.mitvertraege[0]);
        this.$router.push('/dashboard');
      }).catch(() => {
        localStorage.removeItem('token');
        this.$router.push({ name: 'Login' });
      });
    } else {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>
